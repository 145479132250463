<!-- eslint-disable vue/no-v-html -->
<template>
  <v-skeleton-loader
    v-if="isSkeleton"
    type="article, actions"
  />
  <div v-else>
    <v-card>
      <v-card-title class="justify-center">
        <span>Detalhes da SAC</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col span="12">
              <v-text-field
                v-model="origin"
                label="Origem"
                readonly
                outlined
                dense
              />

              <v-text-field
                v-model="process"
                label="Processo"
                readonly
                outlined
                dense
              />

              <v-text-field
                v-model="protocol"
                label="Protocolo"
                readonly
                outlined
                dense
              />
            </v-col>

            <v-col span="12">
              <v-text-field
                v-model="status"
                label="Status"
                readonly
                outlined
                dense
              />

              <v-text-field
                v-model="creatorName"
                label="Emitido por"
                readonly
                outlined
                dense
              />

              <v-text-field
                v-model="createdAt"
                label="Data da solicitação"
                readonly
                outlined
                dense
              />
            </v-col>

            <v-col span="12">
              <v-text-field
                v-model="regionalManager"
                label="Gerente regional"
                readonly
                outlined
                dense
              />

              <v-text-field
                v-model="leader"
                label="Líder"
                readonly
                outlined
                dense
              />

              <v-text-field
                v-model="employee"
                label="Colaborador"
                readonly
                outlined
                dense
              />
            </v-col>
          </v-row>

          <div
            class="container-description"
            v-html="DetailNonconformity"
          />

          <div v-show="isAnsweredByLeader">
            <p class="container-description-input">
              Investigação de não conformidade e suas causas:
            </p>

            <div
              class="container-description"
              v-html="investigationNonconformity"
            />

            <v-row>
              <v-col span="12">
                <v-text-field
                  v-model="dateOfSolutionAnswered"
                  label="Prazo da resolução"
                  readonly
                  outlined
                  dense
                />
              </v-col>

              <v-col span="12">
                <v-switch
                  v-model="dataFromModal.Immediate_action"
                  label="Ação imediata?"
                  class="ml-2"
                  color="success"
                  readonly
                  inset
                />
              </v-col>
            </v-row>
            <v-divider />
          </div>

          <div v-show="!isAnsweredByLeader">
            <v-form
              v-show="isUserResponsible"
              ref="formLeader"
            >
              <v-row>
                <v-col span="6">
                  <v-dialog
                    ref="dialogSolutionDate"
                    v-model="showCalendarSolutionDate"
                    :return-value.sync="solutionDate"
                    width="400px"
                    persistent
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedSolutionDate"
                        :append-icon="icons.mdiCalendar"
                        :rules="[rules.required]"
                        label="Prazo da resolução"
                        class="mt-2"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="solutionDate"
                      width="400px"
                      locale="pt-BR"
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="showCalendarSolutionDate = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogSolutionDate.save(solutionDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col span="2">
                  <v-switch
                    v-model="isImmediateAction"
                    label="Ação imediata?"
                    class="ml-2"
                    color="success"
                    inset
                  />
                </v-col>
              </v-row>

              <p class="container-description-input">
                Investigação de não conformidade e suas causas:
              </p>

              <vue-editor v-model="description" />
            </v-form>
          </div>

          <div v-show="isFinalized">
            <v-switch
              v-model="dataFromModal.effective_corrective_action"
              label="A ação corretiva é eficaz?"
              class="ml-2"
              color="success"
              readonly
              inset
            />

            <v-switch
              v-model="dataFromModal.implemented_schedule"
              label="A ação corretiva foi implementada no prazo previsto?"
              color="success"
              class="ml-2"
              readonly
              inset
            />

            <p class="container-description-input">
              Verificação da implementação da ação corretiva:
            </p>

            <div
              class="container-description"
              v-html="correctiveActionPreventOccurrenceDescription"
            />
          </div>

          <div v-show="isCallCenterLeader">
            <div v-show="!isFinalized">
              <div v-show="isAnsweredByLeader">
                <v-form ref="formLeaderValidation">
                  <v-switch
                    v-model="isAnEffectiveCorrectiveAction"
                    label="A ação corretiva é eficaz?"
                    class="ml-2"
                    color="success"
                    inset
                  />

                  <v-switch
                    v-model="theScheduleIsImplementedOnThePlannedDate"
                    label="A ação corretiva foi implementada no prazo previsto?"
                    color="success"
                    class="ml-2"
                    inset
                  />

                  <p class="container-description-input">
                    Verificação da implementação da ação corretiva:
                  </p>

                  <vue-editor v-model="correctiveActionDescription" />
                </v-form>
              </div>
            </div>
          </div>

          <div class="chips-container">
            <span
              class="container-chips-text"
            >
              Anexos
            </span>

            <div
              class="chips-container-content"
              :style="{'justify-content': isLoadingGetFile ? 'center': 'flex-start'}"
            >
              <v-progress-circular
                v-show="isLoadingGetFile"
                color="warning"
                indeterminate
              />

              <v-chip
                v-for="file in files"
                v-show="computedViewFileContainer"
                :key="file.id"
                size="small"
                color="warning"
                class="chips-content"
                outlined
                dense
                @click="downloadFile(file.id, file.name)"
              >
                <span v-if="file.id === isLoadingFile ? false : true">{{ file.name }}</span>

                <v-progress-circular
                  v-else
                  :key="file.id"
                  :size="25"
                  color="white"
                  indeterminate
                />
              </v-chip>

              <h4
                v-show="!computedViewFileContainer && !isLoadingGetFile"
                class="font-100"
              >
                - Nenhum anexo
              </h4>
            </div>
          </div>

          <div class="d-flex justify-end mt-6">
            <v-btn
              color="error"
              @click="closeModal()"
            >
              Fechar
            </v-btn>

            <div v-show="!isFinalized">
              <div v-show="!isAnsweredByLeader">
                <v-btn
                  v-show="isALevelTwoUser && !isUserResponsible && isAnsweredByLeader"
                  color="info"
                  class="ml-5"
                  @click="getRequestBody('investigationNonconformity')"
                >
                  <span v-if="!isLoading">Enviar</span>
                  <v-progress-circular
                    v-else
                    color="white"
                    indeterminate
                  />
                </v-btn>

                <v-btn
                  v-show="isALevelTwoUser && isUserResponsible"
                  color="info"
                  class="ml-5"
                  @click="getRequestBody('investigationNonconformity')"
                >
                  <span v-if="!isLoading">Responder</span>
                  <v-progress-circular
                    v-else
                    color="white"
                    indeterminate
                  />
                </v-btn>
              </div>

              <div v-show="isCallCenterLeader">
                <v-btn
                  v-show="isAnsweredByLeader"
                  color="warning"
                  class="ml-5"
                  @click="getRequestBody('correctiveActionVerification')"
                >
                  <span v-if="!isLoading">Finalizar SAC</span>
                  <v-progress-circular
                    v-else
                    color="white"
                    indeterminate
                  />
                </v-btn>
              </div>
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar } from '@mdi/js'
import DOMPurify from 'dompurify'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },

  mixins: [formatters, messages],

  props: {
    data: {
      type: [Object],
      required: true,
    },
  },

  data() {
    return {
      files: [],
      isLoadingFile: false,
      isLoadingGetFile: false,
      isViewingFile: false,
      description: '',
      solutionDate: '',
      correctiveActionDescription: '',

      leaderList: [],
      userProfile: {},
      dataFromModal: {
        employer: undefined,
      },

      userPermissions: [],

      isLoading: false,
      isSkeleton: false,
      isImmediateAction: false,
      showCalendarSolutionDate: false,
      isAnEffectiveCorrectiveAction: false,
      theScheduleIsImplementedOnThePlannedDate: false,

      icons: { mdiCalendar },
    }
  },

  computed: {
    computedViewFileContainer() {
      const isFileLoading = !this.isLoadingGetFile
      const isFileViewing = this.isViewingFile

      return isFileLoading && isFileViewing
    },

    origin() {
      return this.dataFromModal?.origin ?? ''
    },

    process() {
      return this.dataFromModal?.process ?? ''
    },

    protocol() {
      return this.dataFromModal?.protocol ?? ''
    },

    regionalManager() {
      return this.dataFromModal?.manager?.name ?? 'Não atribuído'
    },

    leader() {
      return this.dataFromModal?.leader?.name ?? ''
    },

    employee() {
      return this.dataFromModal?.employer?.name ?? ''
    },

    creatorName() {
      return this.dataFromModal?.user?.name ?? ''
    },

    status() {
      return this.dataFromModal?.status ?? ''
    },

    createdAt() {
      return this.dateFormat(this.dataFromModal?.created_at) ?? ''
    },

    DetailNonconformity() {
      const sanitizeText = this.dataFromModal?.detail_nonconformity

      return DOMPurify.sanitize(sanitizeText)
    },

    investigationNonconformity() {
      const sanitizeText = this.dataFromModal?.investigation_nonconformity

      return DOMPurify.sanitize(sanitizeText)
    },

    correctiveActionPreventOccurrenceDescription() {
      const sanitizeText = this.dataFromModal?.corrective_action_prevent_occurrence

      return DOMPurify.sanitize(sanitizeText)
    },

    isUserResponsible() {
      const userId = this.userProfile?.id

      return userId === this.data?.leader_id
    },

    isAnsweredByLeader() {
      const solutionDate = this.dataFromModal?.solution_date

      return !!solutionDate
    },

    isFinalized() {
      return !!this.dataFromModal.corrective_action_prevent_occurrence
    },

    isALevelTwoUser() {
      return this.userProfile?.hierarchical_profile === 'nivel_2'
    },

    isCallCenterLeader() {
      const profile = this.userProfile?.occupation_sector?.occupation?.name

      return profile === 'ANALISTA DE RELACIONAMENTO PL'
    },

    dateOfSolutionAnswered() {
      return this.formatDate(this.dataFromModal?.solution_date) ?? ''
    },

    computedSolutionDate: {
      get() {
        return this.formatDate(this.solutionDate)
      },

      set() {
      },
    },
  },

  async created() {
    this.leaderList = localStorageSlim.get('leaderList', { decrypt: true })
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.userPermissions = localStorageSlim.get('userPermissions', { decrypt: true })

    await this.getDataFromModal()
  },

  methods: {
    async downloadFile(fileId, fileName) {
      this.isLoadingFile = fileId

      const body = { id: fileId }

      await axiosIns
        .post('/api/v1/sgq/sac_attachment/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']
          const file = new File([response.data], fileName, {
            type: contentType,
          })

          saveAs(file)
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoadingFile = ''
        })
    },

    async getDataFromModal() {
      this.isSkeleton = true

      if (!this.data.id) {
        this.closeModal()

        return
      }

      await axiosIns
        .get(`api/v1/sgq/sac/edit/${this.data.id}`)
        .then(response => {
          this.dataFromModal = response.data.data

          this.files = response.data.data?.attachment

          if (this.files?.length > 0) {
            this.isViewingFile = true
          }
        })
        .catch(error => {
          this.showMessage({
            title: 'Contate a equipe de desenvolvimento',
            text: error.message,
            icon: 'error',
          })
          this.closeModal()
        })
        .finally(() => {
          this.isSkeleton = false
        })
    },

    getRequestBody(type) {
      const {
        // eslint-disable-next-line camelcase
        solutionDate,
        description,
        isImmediateAction,
        dataFromModal: {
          // eslint-disable-next-line camelcase
          employer_id,
          // eslint-disable-next-line camelcase
          users_id,
        },
        theScheduleIsImplementedOnThePlannedDate,
        isAnEffectiveCorrectiveAction,
        correctiveActionDescription,
      } = this

      const body = {
        investigationNonconformity: [{
          investigation_nonconformity: description,
          Immediate_action: isImmediateAction,
          effective_corrective_action: true,
          solution_date: solutionDate,
          employer_id,
          users_id,
        }, this.$refs.formLeader.validate()],
        correctiveActionVerification: [{
          implemented_schedule: theScheduleIsImplementedOnThePlannedDate,
          effective_corrective_action: isAnEffectiveCorrectiveAction,
          corrective_action_prevent_occurrence: correctiveActionDescription,
          employer_id,
          users_id,
        }, this.$refs.formLeaderValidation.validate()],
      }

      if (type in body) {
        const [requestBody, isAValidForm] = body[type]
        this.sendModalData(requestBody, isAValidForm)

        return
      }

      console.error('Request body type is invalid')
    },

    async sendModalData(body, isAValidForm) {
      if (isAValidForm) {
        this.isLoading = true

        await axiosIns
          .put(`api/v1/sgq/sac/update/${this.data.id}`, body)
          .then(() => {
            this.showMessage({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Sua resposta enviada com sucesso!',
            })

            this.updatedTable()
          })
          .catch(error => {
            this.showMessage({
              icon: 'error',
              title: 'Erro ao enviar dados!',
              text: error,
            })
          })
          .finally(() => {
            this.isLoading = false
            this.resetForm()
            this.closeModal()
          })

        return
      }

      await this.showMessage({
        title: 'Formulário inválido',
        text: 'Preencha todos os campos obrigatórios',
      })
    },

    /**
     * Formats a date string in Brazilian standard date format.
     *
     * @param {string} dateString - The date string to format.
     * @returns {string|null} - The formatted date string, or null if dateString is falsy.
     */
    formatDate(dateString) {
      if (!dateString) return null

      const [year, month, day] = dateString.split('-')

      return `${day}/${month}/${year}`
    },

    resetForm() {
      const {
        formLeader,
        formLeaderValidation,
      } = this.$refs
      formLeader.reset()
      formLeaderValidation.reset()

      this.description = ''
      this.correctiveActionDescription = ''
    },
  },
}
</script>

<style scoped>
.container-description {
  width: 100%;
  padding: 15px;
  margin-bottom: 25px;

  font-weight: 400;
  color: #e4e4e4;

  border-radius: 10px;
  border: 0.0556rem solid #4e4a67;
  overflow-y: scroll;
}

.container-description-input {
  font-size: 16px;
  font-weight: 400;
  color: #e4e4e4;

  margin-left: 12px;
}

.chips-container {
  display: flex;
  flex-direction: column;
}

.chips-container-content {
  width: 100%;
  height: auto;
  min-height: 60px ;
  display: flex;
  padding: 12px;
  font-size: 10px;
  gap: 6px;

  border-radius: 5px;
  border: 0.0111rem solid #4e4a67;
}

.container-chips-text {
  display: relative;
  text-align: center;
  margin-bottom: -10px;
  margin-left: 15px;
  background: #312D4B;
  z-index: 1;
  width: 6%;
}

.chips-content {
  margin-right: 0.4444rem;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #d9d9d9;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
