<template>
  <div>
    <v-card class="mb-6">
      <v-card-title class="d-flex justify-content-between">
        <span> Solicitação de ação corretiva - SAC </span>

        <v-spacer />

        <v-btn
          color="info"
          class="mr-4"
          outlined
          @click="openModal('create')"
        >
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>

        <v-btn
          color="warning"
          class="mr-4"
          outlined
          @click="openModal('filter')"
        >
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiFilterPlusOutline }}
          </v-icon>
          Filtro
        </v-btn>

        <v-btn
          class="mr-4"
          outlined
          @click="updatedTable()"
        >
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiFilterRemoveOutline }}
          </v-icon>
          Limpar Filtros
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="itemsTable"
        :loading="isLoading"
        sort-by="name"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ dateFormat(item.created_at) }}
        </template>

        <template v-slot:item.delivery_date="{ item }">
          {{ dateFormat(item.delivery_date) }}
        </template>

        <template v-slot:item.solution_date="{ item }">
          {{ dateFormat(item.solution_date) }}
        </template>

        <template v-slot:item.options="{ item }">
          <v-icon
            medium
            color="warning"
            class="me-2"
            @click="openModal('details', item)"
          >
            {{ icons.mdiEye }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showModal"
      width="700px"
    >
      <ModalSac
        :processes="processes"
        @updatedTable="updatedTable()"
        @close="showModal = false"
      >
      </ModalSac>
    </v-dialog>

    <v-dialog
      v-model="showFilter"
      width="700px"
    >
      <SacFilter
        @filteredData="$event => setTableData($event)"
        @close="showFilter = false"
      >
      </SacFilter>
    </v-dialog>

    <v-dialog
      v-model="showDetails"
      width="900px"
    >
      <SacDetails
        :key="dataDetails.id"
        :data="dataDetails"
        @updatedTable="updatedTable()"
        @close="showDetails = false"
      ></SacDetails>
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiBookmarkMultiple,
  mdiClipboardText,
  mdiEye,
  mdiFilterPlusOutline,
  mdiFilterRemoveOutline,
  mdiPlaylistPlus,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalSac from './ModalSac.vue'
import SacDetails from './SacDetails.vue'
import SacFilter from './SacFilter.vue'

export default {
  components: {
    ModalSac,
    SacFilter,
    SacDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      lastStatus: '',
      processes: [],
      userProfile: {},
      dataDetails: {},
      userPermissions: [],

      headers: [
        {
          text: 'PROTOCOLO',
          value: 'protocol',
          sortable: false,
          align: 'center',
        },
        {
          text: 'COLABORADOR',
          value: 'employer.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'FILIAL',
          value: 'employer.company.fantasy_name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'CRIADO EM',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'GERADO POR',
          value: 'user.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'PROCESSO',
          value: 'process',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA DE ENTREGA',
          value: 'delivery_date',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA DE SOLUÇÃO',
          value: 'solution_date',
          sortable: false,
          align: 'center',
        },
        {
          text: 'OPÇÕES',
          value: 'options',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      isLoading: false,

      showModal: false,
      showFilter: false,
      showDetails: false,

      icons: {
        mdiEye,
        mdiPlaylistPlus,
        mdiClipboardText,
        mdiBookmarkMultiple,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },
    }
  },

  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.userPermissions = localStorageSlim.get('userPermissions', { decrypt: true })
    this.hierarchicalProfile = this.userProfile?.hierarchical_profile

    this.getItemsTable()
    this.getProcesses()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns
        .get('api/v1/sgq/sac/index')
        .then(response => {
          this.itemsTable = response.data.data
        })
        .catch(error => this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' }))
        .finally(() => { this.isLoading = false })
    },

    async getProcesses() {
      await axiosIns
        .get('api/v1/sgq/process/index')
        .then(response => {
          this.processes = response.data.data
        })
        .catch(error => {
          this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
        })
    },

    setTableData(data) {
      this.itemsTable = data
    },

    updatedTable() {
      this.getItemsTable()
      this.dataDetails = {}
    },

    /**
     * Opens a modal of the given type and passes in optional data.
     *
     * @param {string} type - The type of modal to open ('create', 'filter', 'history', or 'details').
     * @param {Object} [data={}] - Optional data to pass to the modal.
     */
    openModal(type, data = {}) {
      const modal = {
        create: () => { this.showModal = true },
        filter: () => { this.showFilter = true },
        details: () => {
          this.showDetails = true
          this.dataDetails = data
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error('The modal type is not valid')
    },
  },
}
</script>
