import { render, staticRenderFns } from "./SacDetails.vue?vue&type=template&id=901b3c12&scoped=true&"
import script from "./SacDetails.vue?vue&type=script&lang=js&"
export * from "./SacDetails.vue?vue&type=script&lang=js&"
import style0 from "./SacDetails.vue?vue&type=style&index=0&id=901b3c12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "901b3c12",
  null
  
)

export default component.exports