<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      <span>Gerar nova SAC</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col span="12">
              <v-autocomplete
                v-model="selectedOrigin"
                :items="origin"
                :rules="[rules.required]"
                label="Origem"
                outlined
                dense
              />

              <v-autocomplete
                v-model="selectedProcessName"
                :items="processes"
                :rules="[rules.required]"
                item-text="description"
                item-value="description"
                label="Processo"
                outlined
                dense
              />

              <v-autocomplete
                v-model="selectedLeaderId"
                :items="leaders"
                item-text="name"
                item-value="id"
                label="Selecionar líder"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-col>

            <v-col span="12">
              <v-autocomplete
                v-model="selectedStatus"
                :items="status"
                :rules="[rules.required]"
                label="Status"
                outlined
                dense
              />

              <v-autocomplete
                v-model="selectedManagerId"
                :items="leaders"
                item-text="name"
                item-value="id"
                label="Selecionar o gerente regional"
                outlined
                dense
              />

              <v-autocomplete
                v-model="selectedEmployeeId"
                :items="employees"
                item-text="name"
                item-value="id"
                label="Selecionar colaborador"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-file-input
            ref="fileInput"
            v-model="files"
            :append-icon="icons.mdiPaperclip"
            prepend-icon=""
            label="Anexos"
            class="mb-0"
            outlined
            multiple
            chips
            dense
          />

          <p class="container-description-input">
            Detalhes da Não conformidade:
          </p>

          <vue-editor v-model="description" />
        </v-form>

        <div class="d-flex justify-end mt-5">
          <v-btn
            color="error"
            @click="closeModal(), resetForm()"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="info"
            class="ml-5"
            @click="sendModalData()"
          >
            <span v-if="!isLoading">Enviar</span>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import utilsMixin from '@/plugins/utilsMixin'

import messages from '@/plugins/showMessageMixin'
import { mdiPaperclip } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },

  mixins: [formatters, messages, utilsMixin],

  props: {
    processes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      files: [],
      fileSizeError: false,
      icons: {
        mdiPaperclip,
      },
      description: '',
      selectedLeaderId: '',
      selectedManagerId: '',
      selectedEmployeeId: '',
      selectedProcessName: '',

      selectedOrigin: '',
      selectedStatus: '',

      leaders: [],
      employees: [],
      origin: ['Externa', 'Interna'],
      status: ['Maior', 'Menor'],

      isLoading: false,
    }
  },

  created() {
    this.leaders = localStorageSlim.get('leaderList', { decrypt: true })
    this.employees = localStorageSlim.get('listEmployees', { decrypt: true })
  },

  methods: {
    async sendModalData() {
      const formIsValid = this.$refs.form.validate()
      const {
        description,
        selectedOrigin,
        selectedStatus,
        selectedProcessName,
        selectedLeaderId,
        selectedManagerId,
        selectedEmployeeId,
      } = this

      const body = {
        process: selectedProcessName,
        origin: selectedOrigin,
        status: selectedStatus,
        detail_nonconformity: description,
        employer_id: selectedEmployeeId,
        leader_id: selectedLeaderId,
        manager_id: selectedManagerId,
      }

      if (formIsValid) {
        this.isLoading = true

        await axiosIns
          .post('api/v1/sgq/sac/store', body)
          .then(response => {
            const { data } = response.data

            if (this.files.length === 0) {
              this.showMessage({
                title: 'Enviado com sucesso',
                text: 'dados enviados com sucesso',
                icon: 'success',
              })
            }

            if (this.files.length > 0) {
              this.sendFiles('/api/v1/sgq/sac_attachment/submit_files', this.files, { id: data?.id })
            }

            this.updatedTable()
          })
          .catch(error => this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' }))
          .finally(() => {
            this.isLoading = false

            this.resetForm()
            this.closeModal()
          })

        return
      }

      this.showMessage({ title: 'Formulário inválido', text: 'preencha todos os campos obrigatórios' })
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()
    },
  },
}
</script>
<style scoped>
.container-description-input {
  font-size: 16px;
  font-weight: 400;
  color: #e4e4e4;

  margin-left: 12px;
}
</style>
