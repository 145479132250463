import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mb-6"},[_c(VCardTitle,{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" Solicitação de ação corretiva - SAC ")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.openModal('create')}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" Novo ")],1),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.openModal('filter')}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterPlusOutline)+" ")]),_vm._v(" Filtro ")],1),_c(VBtn,{staticClass:"mr-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.updatedTable()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterRemoveOutline)+" ")]),_vm._v(" Limpar Filtros ")],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.isLoading,"sort-by":"name","loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}},{key:"item.delivery_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.delivery_date))+" ")]}},{key:"item.solution_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.solution_date))+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"warning"},on:{"click":function($event){return _vm.openModal('details', item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])]}}])})],1),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('ModalSac',{attrs:{"processes":_vm.processes},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showModal = false}}})],1),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}},[_c('SacFilter',{on:{"filteredData":function ($event) { return _vm.setTableData($event); },"close":function($event){_vm.showFilter = false}}})],1),_c(VDialog,{attrs:{"width":"900px"},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}},[_c('SacDetails',{key:_vm.dataDetails.id,attrs:{"data":_vm.dataDetails},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showDetails = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }