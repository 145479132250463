<template>
  <v-card>
    <v-card-title class="justify-center mb-4">
      Filtrar Sac`s
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col span="12">
            <v-text-field
              v-model="protocol"
              label="Protocolo"
              outlined
              dense
            />

            <v-autocomplete
              :items="employees"
              item-text="name"
              item-value="id"
              label="Colaborador"
              outlined
              dense
            />
          </v-col>
          <v-col span="12">
            <v-autocomplete
              v-model="selectedCompanyId"
              :items="companies"
              item-text="fantasy_name"
              item-value="id"
              label="Filial"
              outlined
              dense
            />

            <v-autocomplete
              :items="leaders"
              item-text="name"
              item-value="id"
              label="Líder"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-form>

      <div class="d-flex justify-end mt-4">
        <v-btn
          color="error"
          @click="closeModal(), resetForm()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="getFilterData()"
        >
          <span v-if="!isLoading">enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import localStorageSlim from 'localstorage-slim'

export default {
  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      protocol: '',
      selectedLeaderId: '',
      selectedCompanyId: '',
      selectedEmployeeId: '',

      leaders: [],
      companies: [],
      employees: [],

      isLoading: false,
    }
  },

  created() {
    this.leaders = localStorageSlim.get('leaderList', { decrypt: true })
    this.companies = localStorageSlim.get('listCompanies', { decrypt: true })
    this.employees = localStorageSlim.get('listEmployees', { decrypt: true })
  },

  methods: {
    async getFilterData() {
      const {
        protocol, selectedLeaderId, selectedCompanyId, selectedEmployeeId,
      } = this

      const body = {
        protocol, companies_id: selectedCompanyId, leader_id: selectedLeaderId, employer_id: selectedEmployeeId,
      }

      if (this.hasNonEmptyKey(body)) {
        this.isLoading = true

        await axiosIns
          .post('api/v1/sgq/sac/filter', body)
          .then(response => {
            const { data: filteredData } = response.data

            if (filteredData.length === 0) {
              this.showMessage({
                icon: 'warning',
                title: 'Nenhum registro encontrado!',
                text: 'Tente novamente',
              })

              return
            }

            this.showMessage({ title: 'Sucesso', text: 'Dados obtidos com sucesso', icon: 'success' })
            this.resetForm()
            this.closeModal()
            this.sendFilteredData(filteredData)
          })
          .catch(error => this.showMessage({
            title: 'Contate a equipe de desenvolvimento, erro ao enviar dados',
            text: error.message,
            icon: 'error',
          }))
          .finally(() => {
            this.isLoading = false
          })

        return
      }

      this.showMessage({ title: 'Campos vazios', text: 'Você deve preencher ao menos um dos campos' })
    },

    sendFilteredData(data) {
      this.$emit('filteredData', data)
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()
    },
  },
}
</script>
